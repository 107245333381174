// src/services/apiServices.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const getUserIdFromToken = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token found in localStorage');
    return null;
  }

  console.log('Token:', token); // Log the token

  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    const payload = JSON.parse(jsonPayload); // Decode JWT token
    console.log('Token payload:', payload); // Log the payload
    return payload.id; // Assuming the token payload contains the user ID as `id`
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

export const signup = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/auth/signup`, userData);
    return response.data;
  } catch (error) {
    console.error('Error during signup:', error);
    throw error;
  }
};

export const login = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, credentials);
    return response.data;
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
};

export const loginUser = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/auth/user/login`, credentials);
    console.log('API response:', response); // Add this line for debugging
    const token = response.data.data.token; // Ensure you are accessing the token correctly
    if (token) {
      localStorage.setItem('token', token); // Store the token in localStorage
      console.log('Stored token:', localStorage.getItem('token')); // Log the stored token
    } else {
      console.error('Token not found in response');
    }
    return response.data;
  } catch (error) {
    console.error('Error during user login:', error);
    throw error;
  }
};

export const getProfile = async () => {
  const userId = getUserIdFromToken();
  if (!userId) {
    throw new Error('User ID not found in token');
  }

  try {
    const response = await axios.get(`${API_URL}/auth/${userId}/profile`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching profile:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const logoutUser = () => {
  localStorage.removeItem('token');
};

export const deleteAccount = async () => {
  try {
    const response = await axios.post(`${API_URL}/auth/delete-account`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during account deletion:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const editProfile = async (profileData) => {
  try {
    const response = await axios.put(`${API_URL}/auth/edit-profile`, profileData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error editing profile:', error.response ? error.response.data : error.message);
    throw error;
  }
};
