
import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import OrderDetails from '../components/OrderDetails';


function OrderDetailsPage() {
    return (
      <div>
       <Nav />
       <OrderDetails />
       <Footer />
      </div>
    );
  }
  
  export default OrderDetailsPage;
  