import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    vaccination: "Zeckenimpfung",
    details: `Preis: CHF 47.35 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 16 Jahren
Die Zeckenimpfung wird insgesamt dreimal verabreicht. Es wird jedoch unterschieden zwischen dem normalen- und dem Schnellimpfschema

Normales Impfschema:
- zweite Impfung wird ein Monat nach der ersten Impfung verabreicht
- dritte Impfung wird 5-12 Monate nach der zweiten Impfung

Schnellimpfschema:
- zweite Impfung wird 14 Tage nach der ersten Impfung verabreicht
- dritte Impfung wird 5-12 Monate nach der zweiten Impfung

Die Auffrischimpfung wird nach 10 Jahren empfohlen.`,
  },
  {
    vaccination: "Grippeimpfung",
    details: `Preis: CHF 13.70 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 16 Jahren
Die Impfung ist saisonal abhängig (Start meist im Oktober / November bis Februar)
Keine Auffrischimpfung nötig.`,
  },
  {
    vaccination: "Herpes Zoster",
    details: `Preis: CHF 174.65 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 18 Jahren, empfohlen wird die Impfung ab dem 65. Lebensjahr
Die Impfung besteht aus zwei Impfdosen: Die zweite Dosis im Abstand zwischen 2 – 6 Monaten verabreicht
Es braucht keine Auffrischimpfung.`,
  },
  {
    vaccination: "Diphtherie, Tetanus und Pertussis",
    details: `Preis: CHF 36.30 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 16 Jahren
Die Auffrischimpfung wird nach 10 Jahren empfohlen.`,
  },
  {
    vaccination: "Hepatitis A + B",
    details: `Hepatitis A
Preis: CHF 53.00 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Die Hepatitis A Impfung wird in zwei Dosen verabreicht. Die zweite Impfung wird nach sechs Monaten verabreicht
Auffrischimpfung: Ein Schutz wird mindestens für 25 Jahren gewährleistet. Es wird empfohlen bei Reisen in bestimmten Gebieten dies aufzufrischen
Alter: Ab 16 Jahren

Hepatitis B
Preis: CHF 43.60 CHF zuzüglich CHF 20.00 (Impfservice)
Die Hepatitis B Impfung in drei Dosen verabreicht. Die zweite Impfung erfolgt ein Monat nach der ersten und die dritte Impfung wird 6 Monate nach der zweiten Impfung verabreicht
Eine Auffrischimpfung wird nur bei Bedarf empfohlen
Alter: Ab 16 Jahren

Hepatitis A + B
Preis: CHF 90.30 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Die Hepatitis A + B Impfung in drei Dosen verabreicht. Die zweite Impfung erfolgt ein Monat nach der ersten und die dritte Impfung wird 6 Monate nach der zweiten Impfung verabreicht
Alter: Ab 16 Jahren.`,
  },
  {
    vaccination: "Impfung gegen Mumpf, Masern und Röteln",
    details: `Preis: CHF 36.60 zuzüglich CHF 20.00 (Impfservice)
Die Impfung gegen Mumpf, Masern und Röteln wird in zwei Dosen verabreicht. 
Die zweite Impfung wird mindestens 1 Monat nach der ersten Impfung injiziert.
Diejenigen, welche ab dem Jahr 1963 geboren wurden und unvollständig oder nicht geimpft wurden, sollten sich mit zwei Dosen impfen lassen.`,
  },
]

export default function VaccFAQs() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 sm:py-20 lg:px-8 lg:py-16">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Impfung</h2>
          <p className="mt-6 text-lg leading-8 text-black">
          Unser engagiertes Team in der Apotheke steht Ihnen zur Verfügung, um sicherzustellen, dass Sie die notwendigen Impfungen schnell und bequem erhalten. Innerhalb von nur 15 Minuten können Sie sich bei uns gegen eine Vielzahl von Erkrankungen impfen lassen.
          </p>
          <dl className="mt-6 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.vaccination} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.vaccination}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600 whitespace-pre-line">{faq.details}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
