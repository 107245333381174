import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function Banner() {
  return (
    <div className="relative isolate overflow-hidden bg-[#b7d6d6] py-24 sm:py-32">
      <div
        className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
        aria-hidden="true"
      >
        <div
          className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#5c6a5e] to-[#4a554b] opacity-30"
          style={{
            clipPath:
              'polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <p className="text-lg font-semibold leading-8 tracking-tight text-black">Dreirosen</p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Über uns</h1>
          <p className="mt-6 text-xl leading-8 text-gray-700">
 
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
          <div className="relative lg:order-last lg:col-span-5">
            <svg
              className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-white [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e87443c8-56e4-4c20-9111-55b82fa704e3"
                  width={200}
                  height={200}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M0.5 0V200M200 0.5L0 0.499983" />
                </pattern>
              </defs>
              <rect width="100%" height="100%" strokeWidth={0} fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)" />
            </svg>
            <figure className="border-l border-black pl-8">
              <blockquote className="text-xl font-semibold leading-8 tracking-tight text-gray-900">
                <p>
                  “Ihre Gesundheit hat für uns oberste Priorität, und wir sind bestrebt, ein umfangreiches Sortiment an Produkten und Dienstleistungen anzubieten, um Ihre täglichen Bedürfnisse zu erfüllen.”
                </p>
              </blockquote>
              <figcaption className="mt-8 flex gap-x-4">
                <div className="text-sm leading-6">
                  <div className="font-semibold text-gray-900">Dreirosen Apotheke</div>
                </div>
              </figcaption>
            </figure>
          </div>
          <div className="max-w-xl text-base leading-7 text-gray-900 lg:col-span-7">
            <p>
            Die Dreirosen Apotheke ist eine junge unabhängige Apotheke im Glaibasel. Seit dem Frühling 2014 erfreut sich die alt-eingesessene Drogerie an einem Umbau, neuen Farben und einer integrierten Apotheke. Im Januar 2017 wurde die Lokalität zudem total renoviert, diesmal komplett mit neuem Boden-, Mobiliar- und Lichtkonzept.

 

Frau Ann-Christine Weber hat per August 2022 die leitende Funktion der Dreirosen Apotheke übernommen. 

 

Unser Team nimmt sich gerne Zeit für Sie und Ihre Anliegen. Wir führen ein umfangreiches Sortiment zur täglichen Versorgung und Gesunderhaltung von unseren Quartierbewohnern.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
