
import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import OurSevices from "../components/OurServices";
import FAQs from "../components/FAQs";
import FeatureSections from "../components/FeatureSections";


function OurServicesPage() {
    return (
      <div>
       <Nav />
       <OurSevices />
       <FeatureSections />
       <FAQs />
       <Footer />
      </div>
    );
  }
  
  export default OurServicesPage;
  