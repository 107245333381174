
import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import ProductDetails from '../components/ProductDetails';

function ProductDetailsPage() {
    return (
      <div>
       <Nav />
       <ProductDetails />
       <Footer />
      </div>
    );
  }
  
  export default ProductDetailsPage;
  