import { CheckCircleIcon } from '@heroicons/react/20/solid'

const tiers = [
  {
    name: 'Ausschlusskriterien:',
    id: 'tier-basic',
    features: [
        'Schwangere und Stillende',
        'Kinder',
        'Personen mit Allergien auf Bestandteile der Impfungen ',
        'Immunsuprimierte Personen: Bitte nehmen Sie mit uns direkt Kontakt auf',
        'Fieber in den letzten 48 Stunden',
        'Erkältungssymptome',
    ],
  },
  {
    name: 'Welche Nebenwirkungen können auftreten:',
    id: 'tier-essential',
    features: [
      'Muskelschmerzen',
      'Rötungen',
      'Juckreiz',
      'Erkältungssymptome',
    ],
  },
  {
    name: 'Was müssen Sie mitnehmen?',
    id: 'tier-growth',
    features: [
      'Impfausweis',
      'Krankenversicherungskarte',
      'Ausweis',
      'Allergiepass (wenn vorhanden)',
    ],
  },
]

export default function ImportantInfo() {
  return (
    <div className="bg-[#121929] py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-white">Impfung</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
          Wichtige Informationen
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-white sm:text-center">
        Bevor Sie eine Impfung erhalten, überprüfen Sie bitte die folgenden Kriterien, um die Eignung sicherzustellen.
        </p>
        <div className="mt-20 flow-root">
          <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
            {tiers.map((tier) => (
              <div key={tier.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                <h3 id={tier.id} className="text-base font-semibold leading-7 text-white">
                  {tier.name}
                </h3>
                <ul role="list" className="mt-6 space-y-3 text-sm leading-6 text-white">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckCircleIcon className="h-6 w-5 flex-none text-white-600" aria-hidden="true" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
