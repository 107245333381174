
import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import UserAppointmentTable from "../components/UserAppointmentTable";


function AppointmentManagment() {
    return (
      <div>
       <Nav />
       <UserAppointmentTable />
       <Footer />
      </div>
    );
  }
  
  export default AppointmentManagment;
  