import { LifebuoyIcon, NewspaperIcon, PhoneIcon, PlusIcon, QuestionMarkCircleIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/20/solid'

const cards = [
  {
    name: 'Impfung',
    description: 'Consectetur vel non. Rerum ut consequatur nobis unde. Enim est quo corrupti consequatur.',
    icon: PlusIcon,
  },
  {
    name: 'Wie funktionierts?',
    description: 'Quod possimus sit modi rerum exercitationem quaerat atque tenetur ullam.',
    icon: QuestionMarkCircleIcon,
  },
  {
    name: 'Wichtige Informationen',
    description: 'Ratione et porro eligendi est sed ratione rerum itaque. Placeat accusantium impedit eum odit.',
    icon: ClipboardDocumentCheckIcon,
  },
]

export default function OurServices() {
  return (
    <div className="bg-white py-0 sm:py-0">
      <div className="relative isolate overflow-hidden bg-gray-900 py-20 sm:py-20 mt-0">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="flex flex-col items-center lg:items-start text-center lg:text-left mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Dienstleistung Impfung</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Mit Impfungen schützen wir nicht nur uns selber, sondern auch unsere Mitmenschen – insbesondere diejenigen welche nicht geimpft werden können.
              Gerne können Sie sich bei uns in der Apotheke (innert 15 Minuten) gegen folgende Erkrankungen impfen lassen:
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8">
            {cards.map((card) => (
              <div key={card.name} className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10">
                <card.icon className="h-7 w-5 flex-none text-white" aria-hidden="true" />
                <div className="text-base leading-7">
                  <h3 className="font-semibold text-white">{card.name}</h3>
                  <p className="mt-2 text-gray-300">{card.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
