import AllProducts from "../components/AllProducts"; 
import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import Pagination from "../components/Pagination";
import Slider from "../components/Slider";

function Products() {
    return (
      <div>
       <Nav />
       <Slider />
       <AllProducts />
       <Pagination />
       <Footer />
      </div>
    );
  }
  
  export default Products;
  