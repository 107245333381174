import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export default function PromoSection() {
  const images = [
    '/images/skincare.png',
    '/images/avene.avif',
    '/images/product2.jpg',
    '/images/products5.png',
    '/images/avene.avif',
    '/images/skincare.png',
    '/images/product2.jpg'
  ];

  const [imageIndexes, setImageIndexes] = useState(Array(6).fill(0));

  useEffect(() => {
    const intervals = imageIndexes.map((_, index) => {
      const changeImage = () => {
        setImageIndexes((prevIndexes) => {
          const newIndexes = [...prevIndexes];
          let nextIndex;
          const availableIndexes = images
            .map((_, idx) => idx)
            .filter(idx => !newIndexes.includes(idx));

          nextIndex = availableIndexes[Math.floor(Math.random() * availableIndexes.length)];
          newIndexes[index] = nextIndex;
          return newIndexes;
        });
      };

      changeImage(); 
      return setInterval(changeImage, Math.random() * 6000 + 5000); 
    });

    return () => {
      intervals.forEach(clearInterval);
    };
  }, []);

  return (
    <div className="bg-white mt-5">
      <div className="overflow-hidden pt-32 sm:pt-14">
        <div className="bg-[#376c7a]">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="relative pb-16 pt-48 sm:pb-24">
              <div>
                <h2 id="sale-heading" className="text-3xl font-bold tracking-tight text-white md:text-5xl">
                  Dreirosen Apotheke
                  <br />
                </h2>
                <h2 id="sale-heading" className="mt-2 text-2xl font-bold tracking-tight text-white md:text-2xl">
                  <h4>Mit uns blibbts glaibasel gsund</h4>
                </h2>
                <div className="mt-6 text-base">
                  <a href="#" className="font-semibold text-white">
                    Shop the sale
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>
              </div>

              <div className="absolute -top-32 left-1/2 -translate-x-1/2 transform sm:top-6 sm:translate-x-0">
                <div className="ml-24 flex min-w-max space-x-6 sm:ml-3 lg:space-x-8">
                  <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                    <div className="flex-shrink-0 relative h-64 w-64 md:h-72 md:w-72">
                      <AnimatePresence>
                        <motion.img
                          key={imageIndexes[0]}
                          src={images[imageIndexes[0]]}
                          alt=""
                          className="absolute top-0 left-0 h-full w-full rounded-lg object-cover"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 1 }}
                        />
                      </AnimatePresence>
                    </div>
                    <div className="mt-6 flex-shrink-0 relative h-64 w-64 md:h-72 md:w-72 sm:mt-0">
                      <AnimatePresence>
                        <motion.img
                          key={imageIndexes[1]}
                          src={images[imageIndexes[1]]}
                          alt=""
                          className="absolute top-0 left-0 h-full w-full rounded-lg object-cover"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 1 }}
                        />
                      </AnimatePresence>
                    </div>
                  </div>
                  <div className="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                    <div className="flex-shrink-0 relative h-64 w-64 md:h-72 md:w-72">
                      <AnimatePresence>
                        <motion.img
                          key={imageIndexes[2]}
                          src={images[imageIndexes[2]]}
                          alt=""
                          className="absolute top-0 left-0 h-full w-full rounded-lg object-cover"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 1 }}
                        />
                      </AnimatePresence>
                    </div>
                    <div className="mt-6 flex-shrink-0 relative h-64 w-64 md:h-72 md:w-72 sm:mt-0">
                      <AnimatePresence>
                        <motion.img
                          key={imageIndexes[3]}
                          src={images[imageIndexes[3]]}
                          alt=""
                          className="absolute top-0 left-0 h-full w-full rounded-lg object-cover"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 1 }}
                        />
                      </AnimatePresence>
                    </div>
                  </div>
                  <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                    <div className="flex-shrink-0 relative h-64 w-64 md:h-72 md:w-72">
                      <AnimatePresence>
                        <motion.img
                          key={imageIndexes[4]}
                          src={images[imageIndexes[4]]}
                          alt=""
                          className="absolute top-0 left-0 h-full w-full rounded-lg object-cover"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 1 }}
                        />
                      </AnimatePresence>
                    </div>
                    <div className="mt-6 flex-shrink-0 relative h-64 w-64 md:h-72 md:w-72 sm:mt-0">
                      <AnimatePresence>
                        <motion.img
                          key={imageIndexes[5]}
                          src={images[imageIndexes[5]]}
                          alt=""
                          className="absolute top-0 left-0 h-full w-full rounded-lg object-cover"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 1 }}
                        />
                      </AnimatePresence>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
