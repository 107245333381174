import React, { useState, useEffect, useRef } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const products = [
  {
    id: 1,
    name: 'Product',
    color: 'Lorem Ipsum',
    href: '#',
    imageSrc: process.env.PUBLIC_URL + '/images/skincare.png',
    imageAlt: 'Lorem',
    price: '$10',
  },
  {
    id: 2,
    name: 'Product',
    color: 'Lorem Ipsum',
    href: '#',
    imageSrc: process.env.PUBLIC_URL + '/images/skincare.png',
    imageAlt: 'Lorem',
    price: '$10',
  },
  {
    id: 3,
    name: 'Product',
    color: 'Lorem Ipsum',
    href: '#',
    imageSrc: process.env.PUBLIC_URL + '/images/skincare.png',
    imageAlt: 'Lorem',
    price: '$10',
  },
  {
    id: 4,
    name: 'Product',
    color: 'Lorem Ipsum',
    href: '#',
    imageSrc: process.env.PUBLIC_URL + '/images/skincare.png',
    imageAlt: 'Lorem',
    price: '$10',
  },
  {
    id: 5,
    name: 'Product',
    color: 'Lorem Ipsum',
    href: '#',
    imageSrc: process.env.PUBLIC_URL + '/images/skincare.png',
    imageAlt: 'Lorem',
    price: '$10',
  },
  // Repeat or add more products as needed
];

export default function BestSellers() {
  const [isPaused, setIsPaused] = useState(false);
  const autoPlayRef = useRef(null);

  useEffect(() => {
    if (!isPaused) {
      autoPlayRef.current = setInterval(() => {
        document.querySelector('.react-multi-carousel-track').scrollLeft += document.querySelector('.react-multi-carousel-item').offsetWidth;
      }, 3000);
    }
    return () => clearInterval(autoPlayRef.current);
  }, [isPaused]);

  const handleArrowClick = () => {
    setIsPaused(true);
    clearInterval(autoPlayRef.current);
    setTimeout(() => setIsPaused(false), 5000);
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 768, min: 640 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1
    }
  };

  const CustomLeftArrow = ({ onClick }) => (
    <button
      onClick={() => { onClick(); handleArrowClick(); }}
      className="absolute left-0 z-10 p-4 bg-gray-300 rounded-full top-1/2 transform -translate-y-1/2"
      aria-label="Previous"
    >
      <FaChevronLeft className="text-1xl text-white" />
    </button>
  );

  const CustomRightArrow = ({ onClick }) => (
    <button
      onClick={() => { onClick(); handleArrowClick(); }}
      className="absolute right-0 z-10 p-4 bg-gray-300 rounded-full top-1/2 transform -translate-y-1/2"
      aria-label="Next"
    >
      <FaChevronRight className="text-1xl text-white" />
    </button>
  );

  return (
    <div className="bg-white py-24">
      <div className="mx-auto max-w-7xl px-8 sm:px-10">
        <div className="text-center mb-12">
          <h2 className="text-2xl font-bold text-gray-900">Best Sellers</h2>
          <p className="mt-4 text-lg text-gray-600">Unsere meistverkauften Produkte dieser Saison</p>
        </div>
        <div className="relative">
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={!isPaused}
            autoPlaySpeed={3000}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            itemClass="px-4" // Add padding to separate the cards
            containerClass="relative"
            sliderClass="py-12" // Add padding to the top and bottom of the slider to prevent cutting off
            arrows
          >
            {products.map((product) => (
              <div key={product.id} className="relative bg-white p-4 shadow-lg mx-4">
                <div className="relative h-48 w-full overflow-hidden rounded-lg">
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <div className="relative mt-4 text-center">
                  <h3 className="text-sm font-medium text-gray-900">{product.name}</h3>
                  <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                  <p className="relative text-lg font-semibold text-gray-900 mt-2">{product.price}</p>
                  <button className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#376c7a] hover:bg-[#376c7a] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Add to basket
                  </button>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
