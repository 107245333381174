import { useState } from 'react';
import Search from '../components/Search';
import MyDialog from '../components/MyDialog';

function Test() {
  const [searchOpen, setSearchOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <div className="flex flex-col items-center justify-center h-screen space-y-4">
      <button
        onClick={() => setSearchOpen(true)}
        className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Open Search
      </button>
      <button
        onClick={() => setDialogOpen(true)}
        className="inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
      >
        Open Dialog
      </button>
      <Search open={searchOpen} setOpen={setSearchOpen} />
      <MyDialog open={dialogOpen} setOpen={setDialogOpen} />
    </div>
  );
}

export default Test;
