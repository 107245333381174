import LabNav from "../components/LabNav";
import LabFooter from "../components/LabFooter";
import OurServices from "../components/OurServices";
import VacDetails from "../components/VacDetails";
import ImportantInfo from "../components/ImportantInfo";
import LabHero from "../components/LabHero";

function VacinationsPage() {
    return (
      <div>
       <LabNav />
       <LabHero />
       <OurServices />
       <VacDetails />
       <ImportantInfo />
       <LabFooter />
      </div>
    );
  }
  
  export default VacinationsPage;
