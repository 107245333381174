import LabNav from "../components/LabNav";
import LabFooter from "../components/LabFooter";
import ProgressBar from "../components/ProgressBar";
import AppointmentForm from "../components/AppointmentForm";


function VacAppointmentPage() {
    return (
      <div>
       <LabNav />
       <ProgressBar />
       <AppointmentForm />
       <LabFooter />
      </div>
    );
  }
  
  export default VacAppointmentPage;
