import React, { useEffect, useState } from 'react';
import { getProfile, logoutUser, deleteAccount, editProfile } from '../services/apiService';
import { useNavigate } from 'react-router-dom';

const UserProfile = () => {
  const [profile, setProfile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({ name: '', surname: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profileData = await getProfile();
        setProfile(profileData.data.user);
        setFormData({ name: profileData.data.user.name, surname: profileData.data.user.surname });
      } catch (error) {
        console.error('Error fetching profile:', error);
        setError('Failed to fetch profile information.');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleLogout = () => {
    logoutUser();
    navigate('/loginpage');
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      try {
        const response = await deleteAccount();
        console.log('Delete account response:', response); // Debugging line
        logoutUser(); // Remove token from localStorage
        navigate('/registerpage'); 
      } catch (error) {
        console.error('Error deleting account:', error);
        setError('Failed to delete account.');
      }
    }
  };

  const handleEditProfile = async () => {
    try {
      const response = await editProfile(formData);
      console.log('Edit profile response:', response); // Debugging line
      setProfile({ ...profile, ...formData });
      setEditMode(false);
    } catch (error) {
      console.error('Error editing profile:', error);
      setError('Failed to edit profile.');
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  if (!profile) {
    return <div className="text-center">No profile data available.</div>;
  }

  return (
    <div className="bg-gray-50 min-h-screen">
      <header className="bg-gray-100 border-b border-gray-200 px-4 py-4">
      </header>

      <main className="mx-auto max-w-4xl py-6 sm:px-6 lg:px-8">
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Profile Information</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {editMode ? (
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md p-2 w-full"
                    />
                  ) : (
                    profile.name
                  )}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Surname</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {editMode ? (
                    <input
                      type="text"
                      name="surname"
                      value={formData.surname}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md p-2 w-full"
                    />
                  ) : (
                    profile.surname
                  )}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{profile.email}</dd>
              </div>
            </dl>
            <div className="bg-white px-4 py-5 sm:px-6 flex items-center justify-between">
              <div>
                <h4 className="text-sm font-medium text-gray-900">Edit Profile</h4>
                <p className="mt-1 text-sm text-gray-500">Update your personal information.</p>
              </div>
              {editMode ? (
                <button
                  onClick={handleEditProfile}
                  className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                  Save
                </button>
              ) : (
                <button
                  onClick={() => setEditMode(true)}
                  className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Edit
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="mt-8">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Account Management</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Manage your account settings.</p>
            </div>
            <div className="border-t border-gray-200">
              <div className="px-4 py-5 sm:p-6 flex items-center justify-between">
                <div>
                  <h4 className="text-sm font-medium text-gray-900">Logout</h4>
                  <p className="mt-1 text-sm text-gray-500">
                    Logging out will end your current session and return you to the login page.
                  </p>
                </div>
                <button
                  onClick={handleLogout}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Logout
                </button>
              </div>
              <div className="px-4 py-5 sm:p-6 flex items-center justify-between bg-gray-50">
                <div>
                  <h4 className="text-sm font-medium text-gray-900">Delete Account</h4>
                  <p className="mt-1 text-sm text-gray-500">
                    Deleting your account will permanently remove all your data. This action cannot be undone.
                  </p>
                </div>
                <button
                  onClick={handleDeleteAccount}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Delete Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UserProfile;
