const posts = [
  {
    id: 1,
    title: 'Produkte im Angebot',
    href: '#',
    imageUrl:
    process.env.PUBLIC_URL + '/images/canvas2.png',
  },
  {
    id: 2,
    title: 'Shop',
    href: '#',
    imageUrl:
    process.env.PUBLIC_URL + '/images/canvas1.jpg'
  },
  {
    id: 3,
    title: 'Labordiagnostik',
    href: '#',
    imageUrl:
    process.env.PUBLIC_URL + '/images/canvas3.jpg',
  },
]

export default function CategoryBox() {
  return (
    <div className="bg-white">
        <div className="text-center mb-10 mt-20">
          <h2 className="text-2xl font-bold text-gray-900">Finden Sie mehr Produkte.</h2>
          <p className="mt-4 text-lg text-gray-600">Schauen Sie sich unsere neuesten Produkte, Angebote und mehr an!</p>
        </div>
      <main className="pb-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article
                key={post.id}
                className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
              >
                <img src={post.imageUrl} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                <h3 className="mt-3 text-lg text-center font-semibold leading-6 text-white">
                  <a href={post.href}>
                    <span className="absolute inset-0" />
                    {post.title}
                  </a>
                </h3>
              </article>
            ))}
          </div>
        </div>
      </main>
    </div>
  )
}