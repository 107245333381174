
import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import CategoryProducts from '../components/CategoryProducts';


function CategoryPage() {
    return (
      <div>
       <Nav />
       <CategoryProducts />
       <Footer />
      </div>
    );
  }
  
  export default CategoryPage;
  