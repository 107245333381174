import { Fragment, useState } from 'react'
import { Disclosure, Tab } from '@headlessui/react'
import {
  HeartIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'

// Inline CSS
const styles = `
.magnify-container {
  position: relative;
  overflow: hidden;
  cursor: zoom-in;
}

.magnify-container img {
  transition: transform 0.2s ease-in-out;
}

.magnify-container:hover img {
  transform: scale(2);
}

.magnify-glass {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px; /* Size of the magnifying glass */
  height: 100px; /* Size of the magnifying glass */
  border: 2px solid #fff;
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: none;
}

.magnify-container:hover .magnify-glass {
  display: block;
}
`;

const product = {
  name: 'Product Name',
  price: '$20',
  rating: 2,
  images: [
    {
      id: 1,
      name: 'Angled view',
      src: process.env.PUBLIC_URL + '/images/skincare.png',
    },
  ],
  colors: [
    { name: 'test', bgColor: 'bg-gray-700', selectedColor: 'ring-gray-700' },
    { name: 'test1', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
    { name: 'test2', bgColor: 'bg-gray-500', selectedColor: 'ring-gray-500' },
  ],
  description: `
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
  `,
  details: [
    {
      name: 'Features',
      items: [
        'Lorem Ipsum',
        'Ipsum',
        'Lorem Ipsum',
        'Ipsum',
        'Lorem Ipsum',
        'Ipsum',
        'Lorem',
      ],
    },
    {
      name: 'How to use',
      items: [
        'Lorem Ipsum',
        'Ipsum',
        'Lorem Ipsum',
        'Ipsum',
        'Lorem Ipsum',
        'Ipsum',
        'Lorem',
      ],
    },
  ],
}
const relatedProducts = [
  {
    id: 1,
    name: 'Other product',
    href: '#',
    imageSrc: process.env.PUBLIC_URL + '/images/skincare.png',
    price: '$30',
  },
  {
    id: 2,
    name: 'Other product',
    href: '#',
    imageSrc: process.env.PUBLIC_URL + '/images/skincare.png',
    price: '$30',
  },
  {
    id: 3,
    name: 'Other product',
    href: '#',
    imageSrc: process.env.PUBLIC_URL + '/images/skincare.png',
    price: '$30',
  },
  {
    id: 4,
    name: 'Other product',
    href: '#',
    imageSrc: process.env.PUBLIC_URL + '/images/skincare.png',
    price: '$30',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ProductDetails() {
  const [selectedColor, setSelectedColor] = useState(product.colors[0])

  const handleMouseMove = (e) => {
    const glass = e.currentTarget.querySelector('.magnify-glass');
    const img = e.currentTarget.querySelector('img');
    const { offsetX, offsetY } = e.nativeEvent;
    const { offsetWidth, offsetHeight } = e.currentTarget;

    const posX = (offsetX / offsetWidth) * 100;
    const posY = (offsetY / offsetHeight) * 100;

    glass.style.transform = `translate(-50%, -50%) translate(${posX}%, ${posY}%)`;
    img.style.transformOrigin = `${posX}% ${posY}%`;
  }

  return (
    <div className="bg-white">
      <style>{styles}</style>
      <main className="mx-auto max-w-7xl sm:px-6 sm:pt-16 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
            <Tab.Group as="div" className="flex flex-col-reverse">
              <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                <Tab.List className="grid grid-cols-4 gap-6">
                  {product.images.map((image) => (
                    <Tab
                      key={image.id}
                      className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                    >
                      {({ selected }) => (
                        <>
                          <span className="sr-only">{image.name}</span>
                          <span className="absolute inset-0 overflow-hidden rounded-md">
                            <img src={image.src} alt="" className="h-full w-full object-cover object-center" />
                          </span>
                          <span
                            className={classNames(
                              selected ? 'ring-gray-200' : 'ring-transparent',
                              'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </Tab>
                  ))}
                </Tab.List>
              </div>

              <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                {product.images.map((image) => (
                  <Tab.Panel key={image.id} className="magnify-container" onMouseMove={handleMouseMove}>
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="h-full w-full object-cover object-center sm:rounded-lg"
                    />
                    <div className="magnify-glass"></div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>

            <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">{product.name}</h1>

              <div className="mt-3">
                <h2 className="sr-only">Product information</h2>
                <p className="text-3xl tracking-tight text-gray-900">{product.price}</p>
              </div>

              <div className="mt-6">
                <h3 className="sr-only">Description</h3>

                <div
                  className="space-y-6 text-base text-gray-700"
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
              </div>

              <form className="mt-6">
                <div className="mt-10 flex">
                  <button
                    type="submit"
                    className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-[#679183] px-8 py-3 text-base font-medium text-white hover:bg-[#005b96] focus:outline-none focus:ring-2 focus:ring-[#dfdfde] focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
                  >
                    Add to bag
                  </button>

                  <button
                    type="button"
                    className="ml-4 flex items-center justify-center rounded-md px-3 py-3 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                  >
                    <HeartIcon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
                    <span className="sr-only">Add to favorites</span>
                  </button>
                </div>
              </form>

              <section aria-labelledby="details-heading" className="mt-12">
                <h2 id="details-heading" className="sr-only">
                  Additional details
                </h2>

                <div className="divide-y divide-gray-200 border-t">
                  {product.details.map((detail) => (
                    <Disclosure as="div" key={detail.name}>
                      {({ open }) => (
                        <>
                          <h3>
                            <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                              <span
                                className={classNames(
                                  open ? 'text-[#005b96]' : 'text-gray-900',
                                  'text-sm font-medium'
                                )}
                              >
                                {detail.name}
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="block h-6 w-6 text-gray-800 group-hover:text-gray-800"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel as="div" className="prose prose-sm pb-6">
                            <ul role="list">
                              {detail.items.map((item) => (
                                <li key={item}>{item}</li>
                              ))}
                            </ul>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </div>
              </section>
            </div>
          </div>

          <section aria-labelledby="related-heading" className="mt-10 border-t border-gray-200 px-4 py-16 sm:px-0">
            <h2 id="related-heading" className="text-xl font-bold text-gray-900">
              Customers also bought
            </h2>

            <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
              {relatedProducts.map((product) => (
                <div key={product.id}>
                  <div className="relative">
                    <div className="relative h-72 w-full overflow-hidden rounded-lg">
                      <img
                        src={product.imageSrc}
                        alt={product.name}
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="relative mt-4">
                      <h3 className="text-sm font-medium text-gray-900">{product.name}</h3>
                    </div>
                    <div className="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
                      <div
                        aria-hidden="true"
                        className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
                      />
                      <p className="relative text-lg font-semibold text-white">{product.price}</p>
                    </div>
                  </div>
                  <div className="mt-6">
                    <a
                      href={product.href}
                      className="relative flex items-center justify-center rounded-md border border-transparent bg-gray-100 px-8 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200"
                    >
                      Add to bag<span className="sr-only">, {product.name}</span>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </main>
    </div>
  )
}
