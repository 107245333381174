import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import PromoSection from "../components/PromoSection";
import Slider from '../components/Slider';
import CategoryBox from '../components/CategoryBox';
import Brands from '../components/Brands';
import BestSellers from '../components/BestSellers';
import Banner from '../components/Banner';
import LegalContent from '../components/LegalContent';
import LaborSection from '../components/laborSection';

function Home() {
  return (
    <div>
      <Nav />
      <PromoSection />
      <BestSellers />
      <Banner />
      <CategoryBox />
      <Slider />
      <LaborSection />
      <Brands />
      <LegalContent />
      <Footer />
    </div>
  );
}

export default Home;
