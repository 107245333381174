// src/App.js
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Products from './pages/Products';
import ProductDetailsPage from './pages/ProductDetailsPage';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/CheckoutPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import CategoryPage from './pages/CategoryPage';
import OrderDetailsPage from './pages/OrderDetailsPage';
import AppointmentManagment from './pages/AppointmentManagment';
import OurServicesPage from './pages/OurServicesPage';
import BookAppointmentPage from './pages/BookAppointmentPage';
import VacinationsPage from './pages/VacinationsPage';
import Test from './pages/Test';
import VacAppointmentPage from './pages/VacAppointmentPage';
import ProfilePage from './pages/ProfilePage';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/detailspage" element={<ProductDetailsPage />} />
          <Route path="/cartpage" element={<CartPage />} />
          <Route path="/checkoutpage" element={<CheckoutPage />} />
          <Route path="/loginpage" element={<LoginPage />} />
          <Route path="/registerpage" element={<RegisterPage />} />
          <Route path="/categorypage" element={<CategoryPage />} />
          <Route path="/orderdetailspage" element={<OrderDetailsPage />} />
          <Route path="/appointmentmanagment" element={<AppointmentManagment />} />
          <Route path="/ourservices" element={<OurServicesPage />} />
          <Route path="/bookappointment" element={<BookAppointmentPage />} />
          <Route path="/vacinationspage" element={<VacinationsPage />} />
          <Route path="/test" element={<Test />} />
          <Route path="/vacappointment" element={< VacAppointmentPage />} />
          <Route
            path="/profilepage"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
